// material-ui
import { useTheme } from '@mui/material/styles'

// import logoDark from 'assets/images/logo-dark.svg';
import logo from '../assets/images/navy_logo.png'

import { motion } from 'framer-motion'

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme()

    return (
        <motion.img src={logo} alt="Verv Logo" width="100" />
        /**
         * if you want to use SVG instead of img uncomment following, and comment out <img> element.
         *
        <svg width="92" height="32" viewBox="0 0 92 32" fill="none" xmlns="http://www.w3.org/2000/svg">

        </svg>
         *
         */
    )
}

export default Logo
