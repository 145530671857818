// project imports
import config from '../config'

// action - state management
import * as actionTypes from './actions'

export const initialState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true,
    vervConnects: null,
    selectedSerial: null,
    vervConnectsSerials: [],
    energyAllDevices: null,
    energyPerDevice: null,
    Authed: null,
    isLoading: false,
}

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    let id
    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id
            return {
                ...state,
                isOpen: [id],
            }
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened,
            }
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily,
            }
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius,
            }
        case actionTypes.SET_USER_DEVICES:
            return {
                ...state,
                vervConnects: action.vervConnects,
            }
        case actionTypes.SET_SELECTED_DEVICE:
            return {
                ...state,
                selectedSerial: action.payload,
            }
        case actionTypes.SET_DEVICE_SERIALS:
            return {
                ...state,
                vervConnectsSerials: action.vervConnectsSerials,
            }
        case actionTypes.SET_TIMES_USED_PER_DEVICE:
            return {
                ...state,
                timesUsedPerDevice: action.timesUsedPerDevice,
            }
        case actionTypes.SET_AUTHED:
            return {
                ...state,
                Authed: action.Authed,
            }
        case actionTypes.SET_ENERGY_ALL_DEVICES:
            return {
                ...state,
                energyAllDevices: action.energyAllDevices,
            }
        case actionTypes.SET_ENERGY_PER_DEVICE:
            return {
                ...state,
                energyPerDevice: action.energyPerDevice,
            }
        case actionTypes.SET_SMART_CLIMATE_DEVICES:
            return {
                ...state,
                smartClimateDevices: action.smartClimateDevices,
            }
        case actionTypes.IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            }
        case actionTypes.UPDATE_DEVICE_STATE:
            return {
                ...state,
                smartClimateDevices: action.payload,
            }
        default:
            return state
    }
}

export default customizationReducer
