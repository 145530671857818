import React, { useEffect, useState, useMemo } from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'

let PrivateRoute = ({ children }) => {
    const customization = useSelector((state) => state.customization)

    const [Authed, setAuthed] = useState(customization['Authed'])

    useEffect(() => {
        setAuthed(customization['Authed'])
    }, [customization['Authed']])

    return Authed === false ? (
        <Navigate to="/auth/login" replace={true} />
    ) : (
        children
    )
}

export default PrivateRoute
