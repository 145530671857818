import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

// material-ui
import { styled, useTheme } from '@mui/material/styles'
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material'

import { useSelectedDeviceType } from '../../hooks/useSelectedDeviceType'

// project imports
import Breadcrumbs from '../../components/extended/Breadcrumbs'
import Header from './Header'
import Sidebar from './Sidebar'
import { menuItemsEnergyDevice, menuItemsClimateDevice } from '../../menu-items'
import { SET_MENU } from '../../store/actions'

// assets
import { IconChevronRight } from '@tabler/icons-react'

let drawerWidth = 300

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        ...theme.typography.mainContent,
        ...(!open && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.up('md')]: {
                marginLeft: -(drawerWidth - 20),
                width: `calc(100% - ${drawerWidth}px)`,
            },
            [theme.breakpoints.down('md')]: {
                marginLeft: '20px',
                width: `calc(100% - ${drawerWidth}px)`,
                padding: '16px',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px',
                width: `calc(100% - ${drawerWidth}px)`,
                padding: '16px',
                marginRight: '10px',
            },
        }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            width: `calc(100% - ${drawerWidth}px)`,
            [theme.breakpoints.down('md')]: {
                marginLeft: '20px',
            },
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px',
            },
        }),
    })
)

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'))

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened)
    const dispatch = useDispatch()
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
    }

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd])

    //GET ALL THE SERIALS
    const customization = useSelector((state) => state.customization)
    const [serials, setSerials] = useState(customization.vervConnectsSerials)
    useEffect(() => {
        setSerials(customization.vervConnectsSerials)
    }, [customization.vervConnects])

    const menuItems =
        useSelectedDeviceType() === 'smart_climate'
            ? menuItemsClimateDevice
            : menuItemsEnergyDevice

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}

            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.header,
                    transition: leftDrawerOpened
                        ? theme.transitions.create('width')
                        : 'none',
                }}
            >
                <Toolbar style={{ backdropFilter: `blur(${10}px)` }}>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar
                drawerOpen={leftDrawerOpened}
                drawerToggle={handleLeftDrawerToggle}
                serials={serials}
            />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                {/* breadcrumb */}
                <Breadcrumbs
                    separator={IconChevronRight}
                    navigation={menuItems}
                    icon
                    title
                    rightAlign
                />
                <Outlet />
            </Main>
            {/* <Customization /> */}
        </Box>
    )
}

export default MainLayout
