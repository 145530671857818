import PropTypes from 'prop-types'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Avatar, Box, ButtonBase, Typography } from '@mui/material'

// project imports
import LogoSection from '../LogoSection'
import SearchSection from './SearchSection'
import ProfileSection from './ProfileSection'
import NotificationSection from './NotificationSection'

// assets
import { IconMenu2 } from '@tabler/icons-react'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme()

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto',
                    },
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.verv.white,
                            color: theme.palette.verv.darkBlue,
                            '&:hover': {
                                background: theme.palette.verv.darkBlue,
                                color: theme.palette.verv.white,
                            },
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
                <Box
                    component="span"
                    ml={'10px'}
                    sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}
                >
                    <LogoSection />
                </Box>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            {import.meta.env.VITE_APP_ENV !== 'PRODUCTION' ? (
                <Typography style={{ color: 'blue' }}>
                    {import.meta.env.VITE_APP_ENV} - V1.0
                </Typography>
            ) : null}
            {/* notification & profile */}
            {import.meta.env.VITE_APP_ENV !== 'PRODUCTION' ? (
                <NotificationSection />
            ) : null}
            <ProfileSection />
        </>
    )
}

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func,
}

export default Header
