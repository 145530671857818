import { createRoot } from 'react-dom/client'

// third party
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

// project imports
import * as serviceWorker from './serviceWorker'
import App from './App'
import { store } from './store'

// style + assets
import './assets/scss/style.scss'
import config from './config'

// AWS setup
import { Amplify } from 'aws-amplify'

const myAppConfig = {
    aws_project_region: 'eu-west-2',
    aws_cognito_identity_pool_id: import.meta.env.VITE_APP_COGNITO_POOL_ID
        ? import.meta.env.VITE_APP_COGNITO_POOL_ID
        : 'eu-west-2:e2b52baa-1abb-4410-96d5-7cac9d3506f4',
    aws_cognito_region: 'eu-west-2',
    aws_user_pools_id: import.meta.env.VITE_APP_USER_POOLS_ID
        ? import.meta.env.VITE_APP_USER_POOLS_ID
        : 'eu-west-2_vvsmwHDSY',
    aws_user_pools_web_client_id: import.meta.env.VITE_APP_WEB_CLIENT_ID
        ? import.meta.env.VITE_APP_WEB_CLIENT_ID
        : '5gcb7fvjj3dljgkbjbl6ght7ao',
    oauth: {
        domain: import.meta.env.VITE_APP_COGNITO_DOMAIN
            ? import.meta.env.VITE_APP_COGNITO_DOMAIN
            : 'verv-connect-dev.auth.eu-west-2.amazoncognito.com',
    },
    aws_appsync_graphqlEndpoint: `${import.meta.env.VITE_APP_SYNC_ENDPOINT ? import.meta.env.VITE_APP_SYNC_ENDPOINT : 'https://bof4tej25bhmridgrkn6wsncuu.appsync-api.eu-west-2.amazonaws.com/graphql'}`,
    aws_appsync_region: 'eu-west-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
}

Amplify.configure(myAppConfig)

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('app')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
