import { API } from 'aws-amplify'

import {
    queryEnergyAllDevices,
    queryStandbyMetrics,
    queryVervConnects,
} from '../graphql/queries'
import { queryEnergyAllDevicesMock } from '../utils/mocks/queryEnergyAllDevicesMock'
//TODO - switch out to REACT QUERY/CACHE ??
export const getVervConnects = async () => {
    try {
        const response = await API.graphql({ query: queryVervConnects })
        return response.data
    } catch (error) {
        console.error('Error fetching Verv devices:', error)
        throw error
    }
}

export const getEnergyAllDevices = async (maxDate) => {
    try {
        let response

        // TODO - should LOCAL be mocked and DEV be real data?
        if (import.meta.env.VITE_APP_ENV === 'LOCAL') {
            response = queryEnergyAllDevicesMock
        } else {
            response = await API.graphql({
                query: queryEnergyAllDevices,
                variables: { max_date: maxDate },
            })
        }

        const {
            data: {
                queryEnergyAllDevices: {
                    energy_data: { all_serials },
                },
            },
        } = response

        return all_serials
        //return energyAllDevices;
    } catch (error) {
        console.error('Error fetching energy all devices:', error)
        throw error
    }
}

export const getEnergyPerDevice = async (maxDate) => {
    try {
        let response
        response = await API.graphql({
            query: queryEnergyAllDevices,
            variables: { max_date: maxDate },
        })

        const {
            data: {
                queryEnergyAllDevices: {
                    energy_data: { single_serials },
                },
            },
        } = response

        const energyPerDevice = {}
        single_serials.forEach((device, index) => {
            energyPerDevice[device.serial_number] = {
                energy_all_time: device.energy_all_time,
            }
        })

        return energyPerDevice
        //return energyAllDevices;
    } catch (error) {
        // TODO Needs logging to external service
        console.error('Error fetching times used for devices:', error)
        // TODO thrown errors should be handled
        throw error
    }
}

export const getTimesUsedPerDevice = async (maxDate) => {
    try {
        let response
        response = await API.graphql({
            query: queryStandbyMetrics,
        })

        const {
            data: {
                queryStandbyMetrics: {
                    standby_metric_data: { single_serials },
                },
            },
        } = response

        const timesUsedPerDevice = {}
        single_serials.forEach((device) => {
            timesUsedPerDevice[device.serial_number] = {
                times_used: device.times_used,
            }
        })
        return timesUsedPerDevice
        //return energyAllDevices;
    } catch (error) {
        // TODO Needs logging to external service
        console.error('Error fetching energy all devices:', error)
        // TODO thrown errors should be handled
        throw error
    }
}
