/*
 *
 * Formatting serial number on UI
 * Spec: https://greenrunning.atlassian.net/wiki/spaces/SH/pages/3312779268/Firmware+Serial+Numbers#Serial-number-(UI-Only---for-display-purposes)
 *
 *
 */
export const format_serial = (serial: string): string => {
    const strip_zeros = (input: string) => {
        return input.replace(/^0+/, '')
    }

    const strip_vcp = (input: string): string => {
        return input.replace(/^VCP|VC2P/, '')
    }

    serial = strip_vcp(serial)
    const strip_suffix = (input: string): string => {
        const match = input.match(/^(.*?(ITPN|ISPN|SCCD|ISO2|ISO4))/)
        return match ? match[1] : input
    }

    serial = strip_vcp(serial)
    serial = strip_suffix(serial)

    return serial ? strip_zeros(serial) : '–'
}
