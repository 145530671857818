import { API } from 'aws-amplify'

import { querySmartClimateDevices } from '../graphql/queries'
import { querySmartClimateDevicesMock } from '../utils/mocks/querySmartClimateDevicesMock'

export const getSmartClimateDevices = async () => {
    try {
        let response

        // TODO - should LOCAL be mocked and DEV be real data?
        if (import.meta.env.VITE_APP_ENV === 'LOCAL') {
            response = querySmartClimateDevicesMock
        } else {
            response = await API.graphql({ query: querySmartClimateDevices })
        }
        return response.data.querySmartClimateDevices.items
    } catch (error) {
        //TODO - having no devices is not an error, so shouldn't throw an exception
        //throw error;

        //TODO - this should be logged to a logging service with trace to user
        console.error('Error fetching smart climate devices:', error)
        return []
    }
}
