import { lazy } from 'react'

// project imports
import Loadable from '../components/Loadable'
import MinimalLayout from '../layout/MinimalLayout'

// login option routing
const AuthLogin = Loadable(
    lazy(() => import('../views/authentication/authentication3/Login3'))
)
const AuthRegister = Loadable(
    lazy(() => import('../views/authentication/authentication3/Register3'))
)
const AuthForgotPassword = Loadable(
    lazy(
        () => import('../views/authentication/authentication3/ForgotPassword3')
    )
)

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/auth/login',
            element: <AuthLogin />,
        },
        {
            path: '/auth/register',
            element: <AuthRegister />,
        },
        {
            path: '/auth/forgot-password',
            element: <AuthForgotPassword />,
        },
    ],
}

export default AuthenticationRoutes
