// assets
import {
    IconChartBar,
    IconBolt,
    IconTimeline,
    IconChartAreaLine,
} from '@tabler/icons-react'

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'usage',
    title: 'Device Usage',
    caption: 'You can check your appliance energy usage.',
    type: 'group',
    children: [
        {
            id: 'live',
            title: 'Live Energy Usage',
            type: 'item',
            url: '/live-usage',
            icon: IconBolt,
            breadcrumbs: false,
        },
        {
            id: 'history-of-usage',
            title: 'History Of Usage',
            type: 'item',
            url: '/history-of-usage',
            icon: IconChartBar,
            breadcrumbs: false,
        },
        {
            id: 'timeline',
            title: 'Timeline',
            type: 'item',
            url: '/timeline',
            icon: IconTimeline,
            breadcrumbs: false,
            disabled:
                import.meta.env.VITE_APP_ENV !== 'DEVELOPMENT' ? true : false,
        },
    ],
}

export default pages
