import { toast } from 'react-toastify'
// action - customization reducer
export const SET_MENU = '@customization/SET_MENU'
export const MENU_TOGGLE = '@customization/MENU_TOGGLE'
export const MENU_OPEN = '@customization/MENU_OPEN'
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY'
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS'
export const SET_SELECTED_DEVICE = '@customization/SET_SELECTED_DEVICE'
export const SET_ENERGY_ALL_DEVICES = '@customization/SET_ENERGY_ALL_DEVICES'
export const SET_ENERGY_PER_DEVICE = '@customization/SET_ENERGY_PER_DEVICE'
export const SET_DEVICE_SERIALS = '@customization/SET_DEVICE_SERIALS'
export const SET_USER_DEVICES = '@customization/SET_USER_DEVICES'
export const SET_TIMES_USED_PER_DEVICE =
    '@customization/SET_TIMES_USED_PER_DEVICE'
export const SET_AUTHED = '@customization/SET_AUTHED'
export const SET_SMART_CLIMATE_DEVICES =
    '@customization/SET_SMART_CLIMATE_DEVICES'
export const IS_LOADING = '@customization/IS_LOADING'
export const UPDATE_DEVICE_STATE = '@customization/UPDATE_DEVICE_STATE'

export const LOADING = (message) => {
    return toast.loading(message ? message : 'Please wait...')
}

export const SAVED = (id, message) => {
    toast.update(id, {
        render: message,
        type: 'success',
        autoClose: 3000,
        isLoading: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: 'colored',
    })
}
export const ERROR = (id, message) => {
    if (id && message) {
        toast.update(id, {
            render: message,
            type: 'error',
            autoClose: 5000,
            isLoading: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: 'colored',
        })
    } else {
        if (message) {
            toast.error(message)
        } else {
            toast.error('An Error Occurred')
        }
    }
}

// Action creator
export const isLoading = (isLoading) => {
    return {
        type: IS_LOADING,
        payload: isLoading,
    }
}

// Redux Thunk action creator
export const setSelectedSerial =
    (selectedSerial, shouldNotUpdateLocalStorage) => (dispatch) => {
        if (!shouldNotUpdateLocalStorage) {
            localStorage.setItem(
                'selectedSerial',
                JSON.stringify(selectedSerial)
            )
        }
        dispatch({
            type: SET_SELECTED_DEVICE,
            payload: selectedSerial,
        })
    }

export const updateDeviceState =
    (serialNumber, is_on) => (dispatch, getState) => {
        const { smartClimateDevices } = getState().customization

        const updatedSmartClimateDevices = smartClimateDevices.map((device) => {
            if (device.serial === serialNumber) {
                return { ...device, is_on }
            }
            return device
        })

        dispatch({
            type: UPDATE_DEVICE_STATE,
            payload: updatedSmartClimateDevices,
        })
    }
