import { lazy } from 'react'

// project imports
import MainLayout from '../layout/MainLayout'
import Loadable from '../components/Loadable'

import PrivateRoute from './PrivateRoutes'

// dashboard routing
const DashboardDefault = Loadable(
    lazy(() => import('../views/dashboard/Default'))
)
const AllDevices = Loadable(lazy(() => import('../views/all-devices/index')))

// functionality pages
const LiveData = Loadable(lazy(() => import('../views/live-data/index')))
const HistoryData = Loadable(lazy(() => import('../views/history/index')))
const CompareHistoryData = Loadable(
    lazy(() => import('../views/compare-devices/index'))
)

const EnergyEfficiency = Loadable(
    lazy(() => import('../views/energy-efficiency/index'))
)
const EnergyEfficiencyHistory = Loadable(
    lazy(() => import('../views/energy-efficiency-history/index'))
)
const DeviceSettings = Loadable(
    lazy(() => import('../views/device-settings/index'))
)
const Timeline = Loadable(lazy(() => import('../views/timeline/index')))

const FaultDetection = Loadable(
    lazy(() => import('../views/fault-detection/index'))
)

let PredictiveMaintenance = null

PredictiveMaintenance = Loadable(
    lazy(() => import('../views/predictive-maintenance/index'))
)

const Tariffs = Loadable(lazy(() => import('../views/tariffs/index')))
const EmailNotification = Loadable(
    lazy(() => import('../views/email-notifications/index'))
)

const DeviceOnMap = Loadable(lazy(() => import('../views/map/index')))
const Automation = Loadable(lazy(() => import('../views/automation/index')))

// other pages routing
const AccountSettings = Loadable(
    lazy(() => import('../views/account-settings'))
)

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: (
                <PrivateRoute>
                    <DashboardDefault />
                </PrivateRoute>
            ),
        },
        {
            path: 'dashboard',
            element: (
                <PrivateRoute>
                    <DashboardDefault />
                </PrivateRoute>
            ),
        },
        {
            path: 'all-devices',
            element: (
                <PrivateRoute>
                    <AllDevices />
                </PrivateRoute>
            ),
        },
        {
            path: 'live-usage',
            element: (
                <PrivateRoute>
                    <LiveData />
                </PrivateRoute>
            ),
        },
        {
            path: 'history-of-usage',
            element: (
                <PrivateRoute>
                    <HistoryData />
                </PrivateRoute>
            ),
        },
        {
            path: 'compare-history-of-usage',
            element: (
                <PrivateRoute>
                    <CompareHistoryData />
                </PrivateRoute>
            ),
        },
        {
            path: 'energy-efficiency',
            element: (
                <PrivateRoute>
                    <EnergyEfficiency />
                </PrivateRoute>
            ),
        },
        {
            path: 'energy-efficiency-history',
            element: (
                <PrivateRoute>
                    <EnergyEfficiencyHistory />
                </PrivateRoute>
            ),
        },
        {
            path: 'device-settings',
            element: (
                <PrivateRoute>
                    <DeviceSettings />
                </PrivateRoute>
            ),
        },
        {
            path: 'timeline',
            element: (
                <PrivateRoute>
                    <Timeline />
                </PrivateRoute>
            ),
        },
        {
            path: 'fault-detection',
            element: (
                <PrivateRoute>
                    <FaultDetection />
                </PrivateRoute>
            ),
        },
        {
            path: 'predictive-maintenance',
            element: (
                <PrivateRoute>
                    {PredictiveMaintenance ? <PredictiveMaintenance /> : null}
                </PrivateRoute>
            ),
        },
        {
            path: 'tariffs',
            element: (
                <PrivateRoute>
                    <Tariffs />
                </PrivateRoute>
            ),
        },
        {
            path: 'email-notifications',
            element: (
                <PrivateRoute>
                    <EmailNotification />
                </PrivateRoute>
            ),
        },
        {
            path: 'account-settings',
            element: (
                <PrivateRoute>
                    <AccountSettings />
                </PrivateRoute>
            ),
        },
        {
            path: 'map',
            element: (
                <PrivateRoute>
                    <DeviceOnMap />
                </PrivateRoute>
            ),
        },
        {
            path: 'automation',
            element: (
                <PrivateRoute>
                    <Automation />
                </PrivateRoute>
            ),
        },
    ],
}

export default MainRoutes
