/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const queryApplianceHealthStatus = /* GraphQL */ `
  query QueryApplianceHealthStatus {
    queryApplianceHealthStatus {
      serials_by_status {
        faulty
        healthy
        __typename
      }
      __typename
    }
  }
`;
export const queryAutomationConfig = /* GraphQL */ `
  query QueryAutomationConfig($serials: [String]) {
    queryAutomationConfig(serials: $serials) {
      active
      config_id
      days {
        actions {
          hour
          min
          action
          params {
            turn_on
            set_temp
            __typename
          }
          __typename
        }
        active
        day
        __typename
      }
      nickname
      overides {
        actions {
          hour
          min
          action
          params {
            turn_on
            set_temp
            __typename
          }
          __typename
        }
        active
        dates {
          day
          month
          __typename
        }
        __typename
      }
      serials
      type
      __typename
    }
  }
`;
export const queryCurrencies = /* GraphQL */ `
  query QueryCurrencies {
    queryCurrencies {
      currencies {
        alpha_code
        currency_name
        is_default
        locations
        numeric_code
        __typename
      }
      __typename
    }
  }
`;
export const queryEfficiency = /* GraphQL */ `
  query QueryEfficiency(
    $carbon_multiplier: Float
    $max_date: String
    $n_units_1_day_detail: Int
    $n_units_4_week_detail: Int
    $off_peak_definitions: [OffPeakDefinition]
    $remote_days: [String]
    $remote_threshold_pc: Float
    $serial_number: String!
    $tariff: TariffType2
    $weekend_days: String
    $weekend_threshold_pc: Float
  ) {
    queryEfficiency(
      carbon_multiplier: $carbon_multiplier
      max_date: $max_date
      n_units_1_day_detail: $n_units_1_day_detail
      n_units_4_week_detail: $n_units_4_week_detail
      off_peak_definitions: $off_peak_definitions
      remote_days: $remote_days
      remote_threshold_pc: $remote_threshold_pc
      serial_number: $serial_number
      tariff: $tariff
      weekend_days: $weekend_days
      weekend_threshold_pc: $weekend_threshold_pc
    ) {
      data {
        carbon_multiplier
        efficient
        lastDayCostCarbonKgCO2e
        lastDayCostPounds
        lastDayEfficiencyPercentage
        lastDayEfficient
        lastDayKWh
        lastDayNFailures
        lastDayNSkipped
        lastDayTotalKWh
        lastMonthCostCarbonKgCO2e
        lastMonthCostPounds
        lastMonthEfficiencyPercentage
        lastMonthEfficient
        lastMonthKWh
        lastMonthNFailures
        lastMonthNSkipped
        lastMonthTotalKWh
        lastWeekCostCarbonKgCO2e
        lastWeekCostPounds
        lastWeekEfficiencyPercentage
        lastWeekEfficient
        lastWeekKWh
        lastWeekNFailures
        lastWeekNSkipped
        lastWeekTotalKWh
        off_peak_definition
        tariff
        type
        __typename
      }
      efficiency_data_1_day_detail {
        carbon_multiplier
        metrics_by_time_unit {
          CostCarbonKgCO2e
          CostPounds
          EfficiencyPercentage
          Efficient
          KWh
          NFailures
          NSkipped
          TotalKWh
          end_time
          start_time
          step_days
          step_num
          __typename
        }
        off_peak_definition
        tariff
        time_unit
        type
        __typename
      }
      efficiency_data_4_week_detail {
        carbon_multiplier
        metrics_by_time_unit {
          CostCarbonKgCO2e
          CostPounds
          EfficiencyPercentage
          Efficient
          KWh
          NFailures
          NSkipped
          TotalKWh
          end_time
          start_time
          step_days
          step_num
          __typename
        }
        off_peak_definition
        tariff
        time_unit
        type
        __typename
      }
      efficiency_data_main_page {
        carbon_multiplier
        efficient
        lastDayCostCarbonKgCO2e
        lastDayCostPounds
        lastDayEfficiencyPercentage
        lastDayEfficient
        lastDayKWh
        lastDayNFailures
        lastDayNSkipped
        lastDayTotalKWh
        lastMonthCostCarbonKgCO2e
        lastMonthCostPounds
        lastMonthEfficiencyPercentage
        lastMonthEfficient
        lastMonthKWh
        lastMonthNFailures
        lastMonthNSkipped
        lastMonthTotalKWh
        lastWeekCostCarbonKgCO2e
        lastWeekCostPounds
        lastWeekEfficiencyPercentage
        lastWeekEfficient
        lastWeekKWh
        lastWeekNFailures
        lastWeekNSkipped
        lastWeekTotalKWh
        off_peak_definition
        tariff
        type
        __typename
      }
      remote_office_data {
        lastMonthCostCarbonKgCO2e
        lastMonthCostPounds
        lastMonthEfficiencyPercentage
        lastMonthEfficient
        lastMonthWastedKWh
        lastWeekCostCarbonKgCO2e
        lastWeekCostPounds
        lastWeekEfficiencyPercentage
        lastWeekEfficient
        lastWeekWastedKWh
        __typename
      }
      weekdays_weekend_data {
        lastMonthCostCarbonKgCO2e
        lastMonthCostPounds
        lastMonthEfficiencyPercentage
        lastMonthEfficient
        lastMonthWastedKWh
        lastWeekCostCarbonKgCO2e
        lastWeekCostPounds
        lastWeekEfficiencyPercentage
        lastWeekEfficient
        lastWeekWastedKWh
        __typename
      }
      __typename
    }
  }
`;
export const queryEnergyAllDevices = /* GraphQL */ `
  query QueryEnergyAllDevices(
    $locations: [String]
    $max_date: String
    $sites: [String]
  ) {
    queryEnergyAllDevices(
      locations: $locations
      max_date: $max_date
      sites: $sites
    ) {
      energy_data {
        all_serials {
          cost_all_time {
            end_date
            start_date
            unit
            value
            __typename
          }
          cost_last_day {
            end_date
            start_date
            unit
            value
            __typename
          }
          cost_last_month {
            end_date
            start_date
            unit
            value
            __typename
          }
          cost_last_week {
            end_date
            start_date
            unit
            value
            __typename
          }
          cost_last_year {
            end_date
            start_date
            unit
            value
            __typename
          }
          energy_all_time {
            end_date
            start_date
            unit
            value
            __typename
          }
          energy_last_day {
            end_date
            start_date
            unit
            value
            __typename
          }
          energy_last_month {
            end_date
            start_date
            unit
            value
            __typename
          }
          energy_last_week {
            end_date
            start_date
            unit
            value
            __typename
          }
          energy_last_year {
            end_date
            start_date
            unit
            value
            __typename
          }
          serial_numbers
          __typename
        }
        single_serials {
          energy_all_time {
            end_date
            start_date
            unit
            value
            __typename
          }
          serial_number
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const queryEnergyProductBySerial = /* GraphQL */ `
  query QueryEnergyProductBySerial($serial_number: String) {
    queryEnergyProductBySerial(serial_number: $serial_number) {
      contract_end
      contract_start
      currency
      is_default
      is_dual
      is_fixed
      is_green
      product_id
      product_name
      standing_charges {
        apply_from
        standing_charge_type
        unit_rate
        __typename
      }
      supplier_name
      tariffs {
        apply_from
        rate_type
        time_from
        time_to
        unit_rate
        __typename
      }
      __typename
    }
  }
`;
export const queryEnergyProducts = /* GraphQL */ `
  query QueryEnergyProducts {
    queryEnergyProducts {
      energy_products {
        contract_end
        contract_start
        currency
        is_default
        is_dual
        is_fixed
        is_green
        product_id
        product_name
        standing_charges {
          apply_from
          standing_charge_type
          unit_rate
          __typename
        }
        supplier_name
        tariffs {
          apply_from
          rate_type
          time_from
          time_to
          unit_rate
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const queryEnergySuppliers = /* GraphQL */ `
  query QueryEnergySuppliers($defaults_only: Boolean) {
    queryEnergySuppliers(defaults_only: $defaults_only) {
      energy_suppliers {
        company_number
        display_name
        domestic_supplier
        supplier_name
        __typename
      }
      __typename
    }
  }
`;
export const queryNotificationConfigs = /* GraphQL */ `
  query QueryNotificationConfigs {
    queryNotificationConfigs {
      notification_configs {
        address
        checks_list
        id
        serials_list
        type
        __typename
      }
      __typename
    }
  }
`;
export const queryPowerUsed = /* GraphQL */ `
  query QueryPowerUsed(
    $intervalSize: IntervalSize
    $powerAggregationType: powerAggregationType
    $powerCalculationType: powerCalculationType
    $powerCalculationsRange: DateTimeRange
    $serial_number: String!
  ) {
    queryPowerUsed(
      intervalSize: $intervalSize
      powerAggregationType: $powerAggregationType
      powerCalculationType: $powerCalculationType
      powerCalculationsRange: $powerCalculationsRange
      serial_number: $serial_number
    ) {
      preCalculations {
        aggregation_type
        average_amps
        end_date
        end_time
        start_date
        start_time
        tariff
        timestamp
        total_carbon
        total_cost
        total_kvah
        total_kwh
        __typename
      }
      __typename
    }
  }
`;
export const querySmartClimateDevices = /* GraphQL */ `
  query QuerySmartClimateDevices {
    querySmartClimateDevices {
      items {
        appliance {
          age
          brand
          business
          business_name
          coordinates {
            accuracy
            altitude
            altitude_accuracy
            heading
            latitude
            longitude
            speed
            __typename
          }
          floor
          location
          model
          nickname
          price_paid
          site
          type
          __typename
        }
        climateData {
          feels_like
          humidity
          temperature
          __typename
        }
        controllable
        device_type
        group
        is_on
        serial
        __typename
      }
      __typename
    }
  }
`;
export const queryStandbyMetrics = /* GraphQL */ `
  query QueryStandbyMetrics {
    queryStandbyMetrics {
      standby_metric_data {
        single_serials {
          serial_number
          standby_minutes
          operational_minutes
          times_used
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const queryUtilisation = /* GraphQL */ `
  query QueryUtilisation(
    $day: String
    $month: String
    $serial_number: String
    $year: String
  ) {
    queryUtilisation(
      day: $day
      month: $month
      serial_number: $serial_number
      year: $year
    ) {
      utilisation {
        off_at
        on_at
        total_kwh
        __typename
      }
      __typename
    }
  }
`;
export const queryVervConnects = /* GraphQL */ `
  query QueryVervConnects(
    $count: Int
    $last_s3_item: Boolean
    $serial_number: String
    $start: Int
  ) {
    queryVervConnects(
      count: $count
      last_s3_item: $last_s3_item
      serial_number: $serial_number
      start: $start
    ) {
      items {
        appliance {
          age
          brand
          business
          business_name
          coordinates {
            accuracy
            altitude
            altitude_accuracy
            heading
            latitude
            longitude
            speed
            __typename
          }
          floor
          location
          model
          nickname
          price_paid
          site
          type
          __typename
        }
        device_type
        group
        is_on
        is_receiving_data
        last_connection
        last_s3_item
        last_used
        operational_power_used
        received_within_1w
        received_within_24
        role
        s3_within_1w
        s3_within_24h
        s3_within_28
        serial
        used_within_1w
        used_within_24h
        used_within_28
        utilisation_count
        __typename
      }
      __typename
    }
  }
`;
