// material-ui
import { Typography } from '@mui/material'

// project imports
import NavGroup from './NavGroup'
import { useSelectedDeviceType } from '../../../../hooks/useSelectedDeviceType'
import {
    menuItemsEnergyDevice,
    menuItemsClimateDevice,
} from '../../../../menu-items'

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const selectedDeviceType = useSelectedDeviceType()

    const menuItems =
        selectedDeviceType === 'smart_climate'
            ? menuItemsClimateDevice
            : menuItemsEnergyDevice

    const navItems = menuItems.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />
            default:
                return (
                    <Typography
                        key={item.id}
                        variant="h6"
                        color="error"
                        align="center"
                    >
                        Menu Items Error
                    </Typography>
                )
        }
    })

    return <>{navItems}</>
}

export default MenuList
