// assets
import {
    IconDashboard,
    IconPlugConnected,
    IconChartAreaLine,
    IconMap,
} from '@tabler/icons-react'

// constant
const icons = { IconDashboard }

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false,
        },
        {
            id: 'all-devices',
            title: 'All Devices',
            type: 'item',
            url: '/all-devices',
            icon: IconPlugConnected,
            breadcrumbs: false,
        },
        {
            id: 'compare-history-of-usage',
            title: 'Compare Devices',
            type: 'item',
            url: '/compare-history-of-usage',
            icon: IconChartAreaLine,
            breadcrumbs: false,
        },
        {
            id: 'map',
            title: 'Map',
            type: 'item',
            url: '/map',
            icon: IconMap,
            breadcrumbs: false,
        },
    ],
}

export default dashboard
