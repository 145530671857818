import dashboard from './dashboard'
import devices from './devices'
import usage from './usage'
import other from './other'
import deviceSettings from './deviceSettings'
import energyEfficiency from './energyEfficiency'
import maintenance from './maintenance'

// import pages from './pages';
// import utilities from './utilities';

// ==============================|| MENU ITEMS ||============================== //

const menuItemsEnergyDevice = {
    items: [dashboard, devices, usage, energyEfficiency, maintenance, other],
}

const menuItemsClimateDevice = {
    items: [dashboard, devices, deviceSettings],
}

export { menuItemsEnergyDevice, menuItemsClimateDevice }

//    items: [dashboard, devices, usage, energyEfficiency, maintenance, pages, utilities, other]
