// assets
import { IconSettings, IconSettingsAutomation } from '@tabler/icons-react'

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    title: 'Device Settings',
    caption: 'You can change your appliance information, access and tariffs.',
    id: 'other',
    type: 'group',
    children: [
        {
            id: 'device-settings',
            title: 'Device Settings',
            type: 'item',
            url: '/device-settings',
            icon: IconSettings,
            breadcrumbs: false,
        },
        {
            id: 'automation',
            title: 'Automation',
            type: 'item',
            url: '/automation',
            icon: IconSettingsAutomation,
            breadcrumbs: false,
            disabled: false,
        },
    ],
}

export default other
