import { useSelector } from 'react-redux'

/*
    This hook returns the device type of the selected device.
    It uses the selected serial from the redux store to find the device type.
    If the selected serial is not found in the serials array, it returns undefined. 
*/

const useSelectedDeviceType = () => {
    const vervConnectsSerials = useSelector(
        (state) => state.customization.vervConnectsSerials
    )
    const selectedSerial = useSelector(
        (state) => state.customization.selectedSerial
    )

    if (!selectedSerial || vervConnectsSerials.length === 0) {
        return
    }

    const deviceType = vervConnectsSerials.find(
        (serial) => serial.serial === selectedSerial.id
    )?.deviceType

    return deviceType
}

export { useSelectedDeviceType }
