// assets
import { IconActivity, IconHistory } from '@tabler/icons-react'

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'efficiency',
    title: 'Energy Efficiency',
    caption: 'You can check your appliance energy efficiency.',
    type: 'group',
    children: [
        {
            id: 'energy-efficiency',
            title: 'Energy Efficiency',
            type: 'item',
            url: '/energy-efficiency',
            icon: IconActivity,
            breadcrumbs: false,
        },
        {
            id: 'energy-efficiency-history',
            title: 'History Of Energy Efficiency',
            type: 'item',
            url: '/energy-efficiency-history',
            icon: IconHistory,
            breadcrumbs: false,
            disabled:
                import.meta.env.VITE_APP_ENV !== 'DEVELOPMENT' ? true : false,
        },
    ],
}

export default pages
