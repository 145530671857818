import { useState, useRef, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    Typography,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar'

// project imports
import MainCard from '../../../../components/cards/MainCard'
import Transitions from '../../../../components/extended/Transitions'
import UpgradePlanCard from './UpgradePlanCard'
// import User1 from 'assets/images/users/user-round.svg';

// assets
import { IconLogout, IconSettings } from '@tabler/icons-react'

import { Auth } from 'aws-amplify'
// redux
import { useDispatch, useSelector } from 'react-redux'
import { SET_AUTHED } from '../../../../store/actions'

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme()
    const customization = useSelector((state) => state.customization)
    const navigate = useNavigate()

    const [user, setUser] = useState(null)

    useEffect(() => {
        checkIfLoggedIn()
    }, [])

    async function checkIfLoggedIn() {
        try {
            let user = await Auth.currentAuthenticatedUser()
            setUser(user.attributes)
        } catch (error) {
            console.log(error)
        }
    }

    const getGreeting = () => {
        const date = new Date()
        const hour = date.getHours()
        let greeting = ''
        if (hour >= 0 && hour < 12) {
            greeting = 'Good morning'
        } else if (hour >= 12 && hour < 18) {
            greeting = 'Good afternoon'
        } else {
            greeting = 'Good evening'
        }

        return greeting
    }

    const [sdm, setSdm] = useState(true)
    const [value, setValue] = useState('')
    const [notification, setNotification] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1)
    const [open, setOpen] = useState(false)
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null)

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index)
        handleClose(event)

        if (route && route !== '') {
            navigate(route)
        }
    }
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const prevOpen = useRef(open)
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    const dispatch = useDispatch()
    const [Authed, setAuthed] = useState(customization['Authed'])

    useEffect(() => {
        setAuthed(customization['Authed'])
    }, [customization['Authed']])

    const handleSignOut = () => {
        localStorage.clear()
        Auth.signOut().then(setAuthed(false))
    }

    useEffect(() => {
        dispatch({ type: SET_AUTHED, Authed })
    }, [dispatch, Authed])

    function stringAvatar(name) {
        return `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}`
    }

    return Authed === true ? (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.verv.darkBlue,
                        background: `${theme.palette.verv.darkBlue}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light,
                        },
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0,
                    },
                }}
                icon={
                    <Avatar
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer',
                            color: 'inherit',
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    >
                        {user &&
                            stringAvatar(
                                user['custom:name'] && user['custom:name']
                            )}
                    </Avatar>
                }
                label={
                    <IconSettings
                        stroke={1.5}
                        size="1.5rem"
                        color={theme.palette.verv.darkBlue}
                    />
                }
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="default"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper className={'blurBackground'}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                    className={'blurBackground'}
                                >
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack
                                                direction="row"
                                                spacing={0.5}
                                                alignItems="center"
                                            >
                                                <Typography variant="h4">
                                                    {getGreeting()},
                                                </Typography>
                                                <Typography
                                                    component="span"
                                                    variant="h4"
                                                    sx={{ fontWeight: 400 }}
                                                >
                                                    {user &&
                                                        user['custom:name']}
                                                </Typography>
                                            </Stack>
                                            <Typography variant="subtitle2">
                                                Project Admin
                                            </Typography>
                                        </Stack>
                                    </Box>

                                    <PerfectScrollbar
                                        style={{
                                            height: '100%',
                                            maxHeight: 'calc(100vh - 250px)',
                                            overflowX: 'hidden',
                                        }}
                                    >
                                        <Box sx={{ p: 2 }}>
                                            <Divider />
                                            <UpgradePlanCard />
                                            <Divider />
                                            <List
                                                style={{
                                                    backgroundColor:
                                                        'transparent',
                                                }}
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor:
                                                        theme.palette.background
                                                            .paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down(
                                                        'md'
                                                    )]: {
                                                        minWidth: '100%',
                                                    },
                                                    '& .MuiListItemButton-root':
                                                        {
                                                            mt: 0.5,
                                                        },
                                                }}
                                            >
                                                <ListItemButton
                                                    sx={{
                                                        borderRadius: `${customization.borderRadius}px`,
                                                    }}
                                                    selected={
                                                        selectedIndex === 0
                                                    }
                                                    onClick={(event) =>
                                                        handleListItemClick(
                                                            event,
                                                            0,
                                                            '/account-settings'
                                                        )
                                                    }
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings
                                                            stroke={1.5}
                                                            size="1.3rem"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2">
                                                                Account Settings
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>

                                                <ListItemButton
                                                    sx={{
                                                        borderRadius: `${customization.borderRadius}px`,
                                                    }}
                                                    selected={
                                                        selectedIndex === 4
                                                    }
                                                    onClick={() => {
                                                        handleSignOut()
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout
                                                            stroke={1.5}
                                                            size="1.3rem"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2">
                                                                Logout
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    ) : Authed == false ? (
        <Navigate to="/auth/login" replace={true} />
    ) : (
        <CircularProgress />
    )
}

export default ProfileSection
