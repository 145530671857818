// assets
import { IconReportSearch, IconAlertCircle } from '@tabler/icons-react'

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'maintenance',
    title: 'Maintenance',
    caption: 'You can keep track of your appliance maintenance.',
    type: 'group',
    children: [
        {
            id: 'predictive-maintenance',
            title: 'Predictive Maintenance',
            type: 'item',
            url: '/predictive-maintenance',
            icon: IconReportSearch,
            breadcrumbs: false,
            disabled:
                import.meta.env.VITE_APP_ENV !== 'DEVELOPMENT' ? true : false,
        },
    ],
}

export default pages
