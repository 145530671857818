// assets
import { IconKey } from '@tabler/icons-react'

// constant
const icons = {
    IconKey,
}

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'devices',
    title: 'devices',
    caption: 'Please pick a device',
    type: 'group',
}

export default pages
